<template>
  <TosListVuetify entityName="nazioni" :headers="headers" :validateFunction="validate" >
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-text-field label="Codice" v-model="slotProps.data.codice___LIKE" />
          </v-col>
          <v-col cols="4">
            <v-text-field label="Descrizione" v-model="slotProps.data.descrizione___LIKE" />
          </v-col>
          <v-col cols="4">
            <v-text-field label="Area" v-model="slotProps.data.area___LIKE" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-slot:editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
            <v-col cols="2">
              <v-text-field :label="tLabel('codice') + '*'"  v-model="slotProps.data.codice" :rules="[rules.required]" :error-messages="slotProps.errors.codice" />
            </v-col>
            <v-col cols="5">
              <v-text-field :label="tLabel('descrizione') + '*'"  v-model="slotProps.data.descrizione" :rules="[rules.required]" :error-messages="slotProps.errors.descrizione" />
            </v-col>
            <v-col cols="5">
              <v-text-field :label="tLabel('area') + '*'"  v-model="slotProps.data.area"  :error-messages="slotProps.errors.area" />
            </v-col>
        </v-row>
      </div>
    </template>

  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "Nazioni",
  data() {
    return {
      nazioni: [],
      headers: [
        { value: "action",  text: ""},
        { value: "codice",  text: "codice"},
        { value: "descrizione", text: "descrizione"},
        { value: "area",  text: "area"},
      ],
    };
  },
  service: null,
  created() {
  },
  async mounted(){
    this.nazioni=(await this.$api.get(this.$apiConfiguration.BASE_PATH+"nazioni/shortList")).data;
  },
  methods: {
    validate(item, errors) {
      if(item.codice.length!=2) errors.add("codice","Deve essere 2 caratteri");
    }
  },
  components: {
    TosListVuetify,
  },
  mixins: [FormatsMixins],
};
</script>